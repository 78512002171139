var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-layout-content",
        [
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-card",
                    {
                      attrs: {
                        title:
                          _vm.mode === "create"
                            ? _vm.$t("lbl_purchase_requisition_create")
                            : _vm.$t("lbl_purchase_requisition_view")
                      }
                    },
                    [
                      _c(
                        "a-row",
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "a-form",
                                _vm._b(
                                  {
                                    attrs: {
                                      layout: "vertical",
                                      form: _vm.form
                                    }
                                  },
                                  "a-form",
                                  _vm.formItemLayout,
                                  false
                                ),
                                [
                                  _c(
                                    "a-row",
                                    [
                                      _c(
                                        "a-col",
                                        { attrs: { span: 12 } },
                                        [
                                          _vm.show.purchaseRequisitionNumber
                                            ? _c(
                                                "a-form-model-item",
                                                {
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "lbl_purchase_requisition_number"
                                                    )
                                                  }
                                                },
                                                [
                                                  _c("a-input", {
                                                    directives: [
                                                      {
                                                        name: "decorator",
                                                        rawName: "v-decorator",
                                                        value:
                                                          _vm.formRules
                                                            .purchaseRequisitionNumber
                                                            .decorator,
                                                        expression:
                                                          "\n                          formRules.purchaseRequisitionNumber.decorator\n                        "
                                                      }
                                                    ],
                                                    attrs: {
                                                      disabled:
                                                        _vm.disabledForm
                                                          .purchaseRequisitionNumber,
                                                      name: _vm.$t(
                                                        "lbl_purchase_requisition_number"
                                                      ),
                                                      placeholder: _vm.$t(
                                                        "lbl_purchase_requisition_number_placeholder"
                                                      ),
                                                      autocomplete: "off"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label: _vm.$t("lbl_branch")
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "decorator",
                                                      rawName: "v-decorator",
                                                      value:
                                                        _vm.formRules.branch
                                                          .decorator,
                                                      expression:
                                                        "formRules.branch.decorator"
                                                    }
                                                  ],
                                                  attrs: {
                                                    disabled:
                                                      _vm.disabledForm.branch,
                                                    name: _vm.$t("lbl_branch"),
                                                    placeholder: _vm.$t(
                                                      "lbl_branch_placeholder"
                                                    ),
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    loading: _vm.loading.branch,
                                                    allowClear: true
                                                  },
                                                  on: {
                                                    search: function(value) {
                                                      return _vm.getBranch(
                                                        value
                                                      )
                                                    },
                                                    change: function(value) {
                                                      return _vm.changeDropDown(
                                                        value,
                                                        "branch"
                                                      )
                                                    }
                                                  }
                                                },
                                                _vm._l(_vm.dataBranch, function(
                                                  data,
                                                  index
                                                ) {
                                                  return _c(
                                                    "a-select-option",
                                                    {
                                                      key: index,
                                                      attrs: { value: data.id }
                                                    },
                                                    [
                                                      _c(
                                                        "a-tooltip",
                                                        [
                                                          _c(
                                                            "template",
                                                            { slot: "title" },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    data.name
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                data.name
                                                              ) +
                                                              " "
                                                          )
                                                        ],
                                                        2
                                                      )
                                                    ],
                                                    1
                                                  )
                                                }),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label: _vm.$t("lbl_requester")
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "decorator",
                                                      rawName: "v-decorator",
                                                      value:
                                                        _vm.formRules.requester
                                                          .decorator,
                                                      expression:
                                                        "formRules.requester.decorator"
                                                    }
                                                  ],
                                                  attrs: {
                                                    disabled:
                                                      _vm.disabledForm
                                                        .requester,
                                                    name: _vm.$t(
                                                      "lbl_requester"
                                                    ),
                                                    placeholder: _vm.$t(
                                                      "lbl_requester_placeholder"
                                                    ),
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    loading:
                                                      _vm.loading.requester,
                                                    allowClear: true
                                                  },
                                                  on: {
                                                    search: function(value) {
                                                      return _vm.getRequester(
                                                        value
                                                      )
                                                    },
                                                    change: _vm.onCheckMandatory
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataRequester,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.id
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      "" +
                                                                        data.firstName
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  "" +
                                                                    data.firstName
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label: _vm.$t("lbl_date")
                                              }
                                            },
                                            [
                                              _c("a-date-picker", {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value:
                                                      _vm.formRules.date
                                                        .decorator,
                                                    expression:
                                                      "formRules.date.decorator"
                                                  }
                                                ],
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  name: _vm.$t("lbl_date"),
                                                  placeholder: _vm.$t(
                                                    "lbl_date_placeholder"
                                                  ),
                                                  format:
                                                    _vm.DEFAULT_DATE_FORMAT,
                                                  disabled:
                                                    _vm.disabledForm.date
                                                },
                                                on: {
                                                  change: _vm.onCheckMandatory
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-col",
                                        { attrs: { span: 12 } },
                                        [
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  "lbl_supplier_name"
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "decorator",
                                                      rawName: "v-decorator",
                                                      value:
                                                        _vm.formRules
                                                          .supplierName
                                                          .decorator,
                                                      expression:
                                                        "formRules.supplierName.decorator"
                                                    }
                                                  ],
                                                  attrs: {
                                                    disabled:
                                                      _vm.disabledForm
                                                        .requester,
                                                    name: _vm.$t(
                                                      "lbl_supplier_name"
                                                    ),
                                                    placeholder: _vm.$t(
                                                      "lbl_supplier_name_placeholder"
                                                    ),
                                                    showSearch: true,
                                                    "filter-option": false,
                                                    loading:
                                                      _vm.loading.supplierName,
                                                    allowClear: true,
                                                    "label-in-value": ""
                                                  },
                                                  on: {
                                                    search: function(value) {
                                                      return _vm.getSupplier(
                                                        value
                                                      )
                                                    }
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataSupplier,
                                                  function(data) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: data.id,
                                                        attrs: {
                                                          value: data.id
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              data.firstName
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  "lbl_request_type"
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "decorator",
                                                      rawName: "v-decorator",
                                                      value:
                                                        _vm.formRules
                                                          .requestType
                                                          .decorator,
                                                      expression:
                                                        "formRules.requestType.decorator"
                                                    }
                                                  ],
                                                  attrs: {
                                                    disabled:
                                                      _vm.disabledForm
                                                        .requestType,
                                                    name: _vm.$t(
                                                      "lbl_request_type"
                                                    ),
                                                    placeholder: _vm.$t(
                                                      "lbl_request_type_placeholder"
                                                    ),
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    loading:
                                                      _vm.loading.requestType,
                                                    allowClear: true
                                                  },
                                                  on: {
                                                    change: function(value) {
                                                      return _vm.changeDropDown(
                                                        value,
                                                        "requestType"
                                                      )
                                                    }
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataRequestType,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: { value: data }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(data) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label: _vm.$t("lbl_description")
                                              }
                                            },
                                            [
                                              _c("a-textarea", {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value:
                                                      _vm.formRules.description
                                                        .decorator,
                                                    expression:
                                                      "formRules.description.decorator"
                                                  }
                                                ],
                                                attrs: {
                                                  disabled:
                                                    _vm.disabledForm
                                                      .description,
                                                  name: _vm.$t(
                                                    "lbl_description"
                                                  ),
                                                  placeholder: _vm.$t(
                                                    "lbl_description_placeholder"
                                                  )
                                                },
                                                on: {
                                                  change: _vm.onCheckMandatory
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm.mode != "create"
                                            ? _c(
                                                "a-form-model-item",
                                                {
                                                  attrs: {
                                                    label: _vm.$t("lbl_status")
                                                  }
                                                },
                                                [
                                                  _c("a-input", {
                                                    directives: [
                                                      {
                                                        name: "decorator",
                                                        rawName: "v-decorator",
                                                        value:
                                                          _vm.formRules.status
                                                            .decorator,
                                                        expression:
                                                          "formRules.status.decorator"
                                                      }
                                                    ],
                                                    attrs: {
                                                      disabled: true,
                                                      name: _vm.$t(
                                                        "lbl_status"
                                                      ),
                                                      placeholder: _vm.$t(
                                                        "lbl_status_placeholder"
                                                      ),
                                                      autocomplete: "off"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { staticClass: "mt-2", attrs: { span: 24 } },
                            [
                              _c("TableCustom", {
                                attrs: {
                                  idtable: "table1",
                                  dataSource: _vm.dataListItems,
                                  columns: _vm.columnsTable,
                                  scroll: { x: "calc(700px + 50%)" },
                                  paginationcustom: false,
                                  defaultPagination: true,
                                  loading: _vm.loading.table,
                                  onSelectChange: _vm.onSelectChange,
                                  selectedRowKeys: _vm.selectedRowKeys,
                                  handleInput: _vm.handleInput,
                                  handleSearchSelectTable:
                                    _vm.handleSearchSelectTable,
                                  handleSelect: _vm.handleSelectTable
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { staticClass: "mt-2 mb-2", attrs: { span: 12 } },
                            [
                              _c(
                                "a-button",
                                {
                                  staticStyle: {
                                    width: "180px",
                                    "margin-right": "1rem"
                                  },
                                  attrs: {
                                    type: "primary",
                                    disabled: _vm.disabledButton.addRow
                                  },
                                  on: { click: _vm.handleAddRow }
                                },
                                [
                                  _vm._v(
                                    " + " + _vm._s(_vm.$t("lbl_add_row")) + " "
                                  )
                                ]
                              ),
                              _c(
                                "a-button",
                                {
                                  staticStyle: {
                                    width: "180px",
                                    "margin-right": "1rem"
                                  },
                                  attrs: {
                                    type: "danger",
                                    disabled: _vm.disabledButton.deleteRow
                                  },
                                  on: { click: _vm.showConfirmation }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("lbl_delete_row")) + " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            {
                              staticClass: "mt-3",
                              attrs: { span: 12, align: "end" }
                            },
                            [
                              _c(
                                "a-tag",
                                {
                                  staticStyle: { "font-size": "13px" },
                                  attrs: { color: "grey" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("lbl_total_found")) +
                                      " : " +
                                      _vm._s(_vm.dataListItems.length) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            {
                              staticClass: "mt-2 mb-2",
                              staticStyle: {
                                display: "flex",
                                "justify-content": "flex-end"
                              },
                              attrs: { span: 24 }
                            },
                            [
                              _vm.$can(
                                "update",
                                "approver-purchase-requisition"
                              )
                                ? _c(
                                    "a-button",
                                    {
                                      staticStyle: {
                                        width: "180px",
                                        "margin-right": "1rem"
                                      },
                                      attrs: {
                                        type: "danger",
                                        loading: _vm.loading.reject,
                                        disabled: _vm.disabledButton.reject
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.onClickButton("reject")
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("lbl_reject")) + " "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.$can(
                                "update",
                                "approver-purchase-requisition"
                              )
                                ? _c(
                                    "a-button",
                                    {
                                      staticStyle: {
                                        width: "180px",
                                        "margin-right": "1rem"
                                      },
                                      attrs: {
                                        type: "primary",
                                        loading: _vm.loading.approve,
                                        disabled: _vm.disabledButton.approve
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.onClickButton("approve")
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("lbl_approve")) +
                                          " "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.$can(
                                "update",
                                "approver-purchase-requisition"
                              )
                                ? _c(
                                    "a-button",
                                    {
                                      staticStyle: {
                                        width: "180px",
                                        "margin-right": "1rem"
                                      },
                                      attrs: {
                                        type: "danger",
                                        loading: _vm.loading.cancel,
                                        disabled: _vm.disabledButton.cancel
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.onClickButton("cancel")
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("lbl_cancel")) + " "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.$can("create", "purchase-requisition") ||
                              _vm.$can("update", "purchase-requisition")
                                ? _c(
                                    "a-button",
                                    {
                                      staticStyle: {
                                        width: "180px",
                                        "margin-right": "1rem"
                                      },
                                      attrs: {
                                        type: "primary",
                                        loading: _vm.loading.submit,
                                        disabled: _vm.disabledButton.submit
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.onClickButton("submit")
                                        }
                                      }
                                    },
                                    [_vm._v(" Submit ")]
                                  )
                                : _vm._e(),
                              _vm.$can("create", "purchase-requisition")
                                ? _c(
                                    "a-button",
                                    {
                                      staticStyle: {
                                        width: "180px",
                                        "margin-right": "1rem"
                                      },
                                      attrs: {
                                        type: "primary",
                                        loading: _vm.loading.saveAsDraft,
                                        disabled: _vm.disabledButton.saveAsDraft
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.onClickButton(
                                            "saveAsDraft"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("lbl_save_as_draft")) +
                                          " "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.$can("update", "purchase-requisition")
                                ? _c(
                                    "a-button",
                                    {
                                      staticStyle: {
                                        width: "180px",
                                        "margin-right": "1rem"
                                      },
                                      attrs: {
                                        type: "primary",
                                        loading: _vm.loading.update,
                                        disabled: _vm.disabledButton.update
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.onClickButton("update")
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("lbl_update")) + " "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }